.container-div {
  margin: 0;
  padding: 0;
  height: 74vh;
}

@media (max-width: 767px) {
  .container-div {
    height: 86vh !important;
  }
}

.background-div {
  background-size: cover;
  background-position: center;
  height: 72vh;
}

@media (max-width: 767px) {
  .background-div {
    height: 86vh;
  }
}
.bg-img-head {
  font-size: 46px;
  font-weight: bolder;
}
@media (max-width: 767px) {
  .bg-img-head {
    width: 100% !important;
    font-size: 34px;
    text-align: center;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .bg-img-head {
    width: 100%;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .bg-img-head {
    width: 100%;
  }
}

.bg-container-main {
  width: 100%;
  padding-top: 7vh;
}
@media (max-width: 767px) {
  .bg-container-main {
    padding-top: 3vh;
  }
}
.bg-container-subdiv {
  font-size: 18px;
  padding-top: 1vh;
}
@media (max-width: 767px) {
  .bg-container-subdiv {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .bg-container-subdiv {
    width: 89%;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .bg-container-subdiv {
    width: 75%;
  }
}

.bg-overlap-div {
  background-color: #ffffff;
  height: 17vh;
  border-radius: 22px;
  border: 1px solid #dadada;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: -94px;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .bg-overlap-div {
    width: 90%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .bg-overlap-div {
    margin-top: -82px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .bg-container-subdiv {
    width: 75%;
  }
}

.overlay-bg-sub-points {
  display: flex;
  align-items: center;
  gap: 10px;
}
